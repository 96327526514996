import React from 'react';
import useDocumentTitle from '../template/components/useDocumentTitle';
import Header from '../components/Header';
import Call2action from '../template/components/Sections/Call2action';
import Footer from '../template/components/footer/Footer';
import Search from "../components/cards/Search";
import GamesDisplay from "../components/display/GamesDisplay";

const Home1 = () => {
  useDocumentTitle('Game Stash');
  return (
    <div>
      <Header />

      <div className="hero__1">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="hero__left space-y-20">
              <h1 className="hero__title">
                Bienvenido a tu plataforma de juegos y tcg GAME STASH.
              </h1>
              <p className="hero__text txt">
                Game Stash pretende ser la plataforma de compra/venta de
                cartas y juegos de mesa que asegure tus transacciones y
                facilite tus búsquedas en México.
                <br/>
                Visita el mercado y utiliza nuestro buscador para
                encontrar tus cartas por nombre, contacta al vendedor y
                obtén tu producto.
              </p>
              <div
                className="space-x-20 d-flex flex-column flex-md-row
							sm:space-y-20">
                <a className="btn btn-white" href="https://www.facebook.com/game.stash.tcg"
                  title="Facebook" target="_blank" rel="noreferrer"> {/*primary*/}
                  <i className="ri-facebook-circle-fill"></i>
                  &nbsp;Facebook
                </a>


                <a className="btn btn-white" href="https://link.mercadopago.com.mx/gamestash"
                   title="Donar" target="_blank" rel="noreferrer">
                  <i className="ri-hand-heart-line"></i>
                  &nbsp;&nbsp;Donar
                </a>
              </div>

                {/*<div className="box input__box d-flex align-items-center space-x-20">*/}
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <Search classes={"search m-auto"} styles={{maxWidth: 800}}
                      buttonClasses={"result"}/>
                  </div>
                {/*</div>*/}

              </div>
            </div>
            <div className="col-lg-6">
              <img
                className="img-fluid w-full"
                id="tlacuache_right"
                src="/img/Tlacuache/tlacuache_right.png"
                alt="tlacuache"
              />
            </div>
          </div>
        </div>
      </div>


      <GamesDisplay />
      <Call2action />

      <Footer />
    </div>
  );
};

export default Home1;