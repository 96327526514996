import React, {useContext} from 'react';
import Search from "../../components/cards/Search";
import {StoreContext} from "../../actions/stores";

function Hero() {
  const store = useContext(StoreContext)

  return (
    <div>
      <div className="hero_forum bg_white">
        <div className="container">
          <div className="row align-items-center sm:space-y-20">
            <div className="col-lg-6 col-md-6 col-sm-12 space-y-10">
              {/*<h1 className="text-left">{store.name}</h1>*/}
              <p>
                <img src={store.main_logo} style={{maxHeight: 250}}/>
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <Search classes={"search m-auto"} styles={{maxWidth: 400}}
                buttonClasses={"result"}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
