import React from 'react';
import Search from "../cards/Search";

function HeroMarket() {


  return (
    <div>
      <div className="hero_forum bg_white">
        <div className="container">
          <div className="row align-items-center sm:space-y-20">
            <div className="col-lg-6 col-md-6 col-sm-12 space-y-10">
              <h1 className="text-left">Bienvenidos al Stash</h1>
              <p>
                Aquí podrás encontrar las cartas que la gente vende,
                buscar por nombre o usar los filtros avanzados para facilitar tu búsqueda.
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <Search classes={"search m-auto"} styles={{maxWidth: 400}}
                buttonClasses={"result"}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroMarket;
