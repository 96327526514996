import React from 'react';
import 'reactjs-popup/dist/index.css';
import PriceReferencePopUp from "../popups/PriceReferencePopUp"
import SellersPopUp from "../popups/SellersPopUp"

function CardMarketplace({product, cartAvailabilities, setCartAvailabilities}) {
  return (
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6" >
            <div className="card__item four">
              <div className="card_body space-y-10">
                {/* =============== */}
                <div className="creators space-x-10">
                  {product.expansion} {product.numeration}
                </div>
                <div className="card_head">
                  {/*<Link to="Item-details">*/}
                    <img src={product.image_url} alt="Imagen no disponible" />
                  {/*</Link>*/}
                  {/*<Link to="#" className="likes space-x-3">*/}
                  {/*  <i className="ri-heart-3-fill" />*/}
                  {/*  <span className="txt_sm">{product.likes}k</span>*/}
                  {/*</Link>*/}
                </div>
                {/* =============== */}
                <h6 className="card_title">{product.name}</h6>
                <div className="card_footer d-block space-y-10">
                  { product.is_available_on_market ? (
                    <div className="card_footer justify-content-between">
                      <div>
                        <p className="txt_sm">Disponibles: {product.available_quantity > 10 ? "10+" : product.available_quantity}</p>
                        <p className="txt_sm">
                          Desde: <span className="color_green txt_sm">{product.min_price}</span>
                        </p>
                      </div>
                    </div>
                  ): (
                      <div className="card_footer justify-content-between">
                        <span>Agotado</span>
                      </div>
                  )}

                  <div className="hr"/>
                  <div
                      className="d-flex
								align-items-center
								space-x-10
								justify-content-between">
                    <div
                        className="d-flex align-items-center
									space-x-5">
                      <i className="ri-history-line"/>
                      <PriceReferencePopUp price_references={product.price_references}/>
                    </div>
                    {
                      product.is_available_on_market ? (
                        <SellersPopUp
                            product_id={product.id}
                            cartAvailabilities={cartAvailabilities}
                            setCartAvailabilities={setCartAvailabilities}
                        />
                      ) : (
                          <button className="btn btn-sm light_bg" disabled>
                            Ver Vendedores
                          </button>
                      )
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>

  );
}

export default CardMarketplace;