import React from 'react';
import { useState, useContext } from 'react';
import {Link} from 'react-router-dom';
import MobileMenu from '../../template/components/header/Menu/MobileMenu';
import Search from "../../components/cards/Search";
import { AuthContext } from "../../actions/auth"
import { StoreContext } from "../../actions/stores"


const Header = () => {
  const [isActive, setActive] = useState(false);
  const {user} = useContext(AuthContext);
  const store = useContext(StoreContext);

  const toggleClass = () => {
    setActive(!isActive);
  };

  const clearAuth = () => {
      localStorage.setItem("access", null)
      localStorage.setItem("refresh", null)
      window.location = "/"
  }

  return (
    <div>

      <header className="header__1">
        <div className="container">
          <div className="wrapper js-header-wrapper">
            <div className="header__logo">
              <Link to="/">
                <img
                    className="header__logo"
                    id="logo_js"
                    src={store?.logo}
                    alt="logo"
                />
                <span className="ml-3">{store?.name}</span>
              </Link>
            </div>
            <div className="header__menu">
              {/*<ul className="d-flex space-x-20">*/}
              {/*  <li>*/}
              {/*    <Link className="color_black" to={"/stash"}>*/}
              {/*      Mercado*/}
              {/*    </Link>*/}
              {/*  </li>*/}
              {/*</ul>*/}
            </div>
            <Search classes={"header__search"} buttonClasses={"header__result"}/>
            <div className="header__btns">
              <Link className="mr-5" to="/carrito">
                <i className="ri-shopping-cart-line"></i>
                Carrito
              </Link>
            </div>
            <div className="header__btns">
              {user ? (
                  <div className="has_popup">
                    <Link className="color_black" to="/">
                      {user.name} <i className="ri-arrow-drop-down-fill"/>
                    </Link>
                    <ul className="menu__popup space-y-20">
                      <li>
                        <span onClick={clearAuth}>
                          <i className={`ri-close-circle-line`}/>
                          Cerrar sesión
                        </span>
                      </li>
                    </ul>
                  </div>
              ) : (
                  <Link className="btn btn-sm bg_brand" to="/login">
                    Ingresar / Registrarme
                  </Link>
              )}
            </div>
            <div className="header__burger js-header-burger" onClick={toggleClass}/>
            <div className={` header__mobile js-header-mobile  ${isActive ? 'visible' : null} `}>
              <MobileMenu/>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
