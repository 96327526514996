
import style from "./Pagination.module.scss";



function Pagination({page, changePage, pageCount}) {
    let pages = [page];
    // TOdo fix superior limit and maybe always keep n pages
    for (let i = 1; i <= 5; i++) {
        if (page + i <= pageCount) {
            pages.push(page + i);
        }
        if (page - i >= 1){
            pages.unshift(page - i);
        }
    }

    return (
        <div className={style.pagination}>
        {pages.map(
            i => (
                <button className={i===page ? style.active: null}
                    onClick={() => {changePage(i)}}
                    key={i}
                >
                    {i}
                </button>
            )
            )}
        </div>
    )
}

export default Pagination;